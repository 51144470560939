import React, { useCallback, useContext, useEffect, useState } from "react";

import "./Config.css";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Stack,
  Tab,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import CheckedActionButton from "../../../../../components/elements/CheckedActionButto";
import CustomStripedTableRow from "../../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTable from "../../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../../components/elements/Crud/CustomTableBody";
import CustomTableContainer from "../../../../../components/elements/Crud/CustomTableContainer";
import CustomTableCell from "../../../../../components/elements/CustomTableCell";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import configService from "../../../../services/admin/ConfigService";
import DebouncedTextArea from "../../../../../components/elements/DebouncedTextArea";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConfigCre = () => {
  const { configs, selectedAccount, loadConfigs, addMessage, addMessages } =
    useContext(GlobalContext);

  let navigate = useNavigate();

  const [accountWppDefaultSendPermitted, setAccountWppDefaultSendPermitted] = useState(false);

  const [accountCreClosureWppDefaultMessage, setAccountCreClosureWppDefaultMessage] = useState("");
  const [accountCreClosureWppBillMessage, setAccountCreClosureWppBillMessage] = useState("");

  const [accountCreBillNotDueDefaultMessage, setAccountCreBillNotDueDefaultMessage] = useState("");
  const [accountCreBillNotDueInvoiceDefaultMessage, setAccountCreBillNotDueInvoiceDefaultMessage] =
    useState("");

  const [accountCreBillDueTodayDefaultMessage, setAccountCreBillDueTodayDefaultMessage] =
    useState("");
  const [
    accountCreBillDueTodayInvoiceDefaultMessage,
    setAccountCreBillDueTodayInvoiceDefaultMessage,
  ] = useState("");

  const [accountCreBillOverdueDefaultMessage, setAccountCreBillOverdueDefaultMessage] =
    useState("");
  const [
    accountCreBillOverdueInvoiceDefaultMessage,
    setAccountCreBillOverdueInvoiceDefaultMessage,
  ] = useState("");

  const [accountCreDueTodayNotifEmailClientBool, setAccountCreDueTodayNotifEmailClientBool] =
    useState(false);
  const [accountCreDueTodayNotifWppClientBool, setAccountCreDueTodayNotifWppClientBool] =
    useState(false);
  const [accountCreDueTomorrowNotifEmailClientBool, setAccountCreDueTomorrowNotifEmailClientBool] =
    useState(false);
  const [accountCreDueTomorrowNotifWppClientBool, setAccountCreDueTomorrowNotifWppClientBool] =
    useState(false);
  const [accountCreOverdueNotifEmailClientBool, setAccountCreOverdueNotifEmailClientBool] =
    useState(false);
  const [accountCreOverdueNotifWppClientBool, setAccountCreOverdueNotifWppClientBool] =
    useState(false);

  const [userCreDueTodayNotifEmailBool, setUserCreDueTodayNotifEmailBool] = useState(false);
  const [userCreDueTodayNotifWppBool, setUserCreDueTodayNotifWppBool] = useState(false);
  const [userCreDueTodayNotifPushBool, setUserCreDueTodayNotifPushBool] = useState(false);
  const [userCreDueTodayNotifWebBool, setUserCreDueTodayNotifWebBool] = useState(false);
  const [userCreDueTomorrowNotifEmailBool, setUserCreDueTomorrowNotifEmailBool] = useState(false);
  const [userCreDueTomorrowNotifWppBool, setUserCreDueTomorrowNotifWppBool] = useState(false);
  const [userCreDueTomorrowNotifPushBool, setUserCreDueTomorrowNotifPushBool] = useState(false);
  const [userCreDueTomorrowNotifWebBool, setUserCreDueTomorrowNotifWebBool] = useState(false);
  const [userCreOverdueNotifEmailBool, setUserCreOverdueNotifEmailBool] = useState(false);
  const [userCreOverdueNotifWppBool, setUserCreOverdueNotifWppBool] = useState(false);
  const [userCreOverdueNotifPushBool, setUserCreOverdueNotifPushBool] = useState(false);
  const [userCreOverdueNotifWebBool, setUserCreOverdueNotifWebBool] = useState(false);

  const { setaLoading } = useContext(GlobalContext);

  const handleChangeAccountWppClosureDefaultMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountWppClosureDefaultMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreClosureWppDefaultMessage(event.target.value);
    loadConfigs();
  };

  const handleChangeAccountCreClosureWppBillMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountWppClosureBillMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreClosureWppBillMessage(event.target.value);
    loadConfigs();
  };

  const handleChangeAccountCreBillNotDueDefaultMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountCreBillNotDueDefaultMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreBillNotDueDefaultMessage(event.target.value);
    loadConfigs();
  };

  const handleChangeAccountCreBillNotDueInvoiceDefaultMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountCreBillNotDueInvoiceDefaultMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreBillNotDueInvoiceDefaultMessage(event.target.value);
    loadConfigs();
  };

  const handleChangeAccountCreBillDueTodayDefaultMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountCreBillDueTodayDefaultMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreBillDueTodayDefaultMessage(event.target.value);
    loadConfigs();
  };

  const handleChangeAccountCreBillDueTodayInvoiceDefaultMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountCreBillDueTodayInvoiceDefaultMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreBillDueTodayInvoiceDefaultMessage(event.target.value);
    loadConfigs();
  };

  const handleChangeAccountCreBillOverdueDefaultMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountCreBillOverdueDefaultMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreBillOverdueDefaultMessage(event.target.value);
    loadConfigs();
  };

  const handleChangeAccountCreBillOverdueInvoiceDefaultMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    console.log(event.target.value);
    configService.updateAccountCreBillOverdueInvoiceDefaultMessage(
      addMessages,
      setaLoading,
      navigate,
      event.target.value,
    );
    setAccountCreBillOverdueInvoiceDefaultMessage(event.target.value);
    loadConfigs();
  };

  useEffect(() => {
    const loadConfigsCre = async () => {
      await loadConfigs();
      setAccountWppDefaultSendPermitted(
        configService.getAccountWppDefaultSendPermittedValue(configs) ?? false,
      );
      setAccountCreClosureWppDefaultMessage(
        configService.getAccountWppClosureDefaultMessageValue(configs) ?? "",
      );
      setAccountCreClosureWppBillMessage(
        configService.getAccountWppClosureBillMessageValue(configs) ?? "",
      );

      setAccountCreBillNotDueDefaultMessage(
        configService.getAccountCreBillNotDueDefaultMessageValue(configs) ?? "",
      );
      setAccountCreBillNotDueInvoiceDefaultMessage(
        configService.getAccountCreBillNotDueInvoiceDefaultMessageValue(configs) ?? "",
      );
      setAccountCreBillDueTodayDefaultMessage(
        configService.getAccountCreBillDueTodayDefaultMessageValue(configs) ?? "",
      );
      setAccountCreBillDueTodayInvoiceDefaultMessage(
        configService.getAccountCreBillDueTodayInvoiceDefaultMessageValue(configs) ?? "",
      );
      setAccountCreBillOverdueDefaultMessage(
        configService.getAccountCreBillOverdueDefaultMessageValue(configs) ?? "",
      );
      setAccountCreBillOverdueInvoiceDefaultMessage(
        configService.getAccountCreBillOverdueInvoiceDefaultMessageValue(configs) ?? "",
      );

      setAccountCreDueTodayNotifEmailClientBool(
        configService.getAccountCreDueTodayNotifEmailBoolValue(configs) ?? false,
      );
      setAccountCreDueTodayNotifWppClientBool(
        configService.getAccountCreDueTodayNotifWppBoolValue(configs) ?? false,
      );
      setAccountCreDueTomorrowNotifEmailClientBool(
        configService.getAccountCreDueTomorrowNotifEmailBoolValue(configs) ?? false,
      );
      setAccountCreDueTomorrowNotifWppClientBool(
        configService.getAccountCreDueTomorrowNotifWppBoolValue(configs) ?? false,
      );
      setAccountCreOverdueNotifEmailClientBool(
        configService.getAccountCreOverdueNotifEmailBoolValue(configs) ?? false,
      );
      setAccountCreOverdueNotifWppClientBool(
        configService.getAccountCreOverdueNotifWppBoolValue(configs) ?? false,
      );
      setAccountCreOverdueNotifEmailClientBool(
        configService.getAccountCreOverdueNotifEmailBoolValue(configs) ?? false,
      );

      setUserCreDueTodayNotifEmailBool(
        configService.getUserCreDueTodayNotifEmailBoolValue(configs) ?? false,
      );
      setUserCreDueTodayNotifWppBool(
        configService.getUserCreDueTodayNotifWppBoolValue(configs) ?? false,
      );
      setUserCreDueTodayNotifPushBool(
        configService.getUserCreDueTodayNotifPushBoolValue(configs) ?? false,
      );
      setUserCreDueTodayNotifWebBool(
        configService.getUserCreDueTodayNotifWebBoolValue(configs) ?? false,
      );
      setUserCreDueTomorrowNotifEmailBool(
        configService.getUserCreDueTomorrowNotifEmailBoolValue(configs) ?? false,
      );
      setUserCreDueTomorrowNotifWppBool(
        configService.getUserCreDueTomorrowNotifWppBoolValue(configs) ?? false,
      );
      setUserCreDueTomorrowNotifPushBool(
        configService.getUserCreDueTomorrowNotifPushBoolValue(configs) ?? false,
      );
      setUserCreDueTomorrowNotifWebBool(
        configService.getUserCreDueTomorrowNotifWebBoolValue(configs) ?? false,
      );
      setUserCreOverdueNotifEmailBool(
        configService.getUserCreOverdueNotifEmailBoolValue(configs) ?? false,
      );
      setUserCreOverdueNotifWppBool(
        configService.getUserCreOverdueNotifWppBoolValue(configs) ?? false,
      );
      setUserCreOverdueNotifPushBool(
        configService.getUserCreOverdueNotifPushBoolValue(configs) ?? false,
      );
      setUserCreOverdueNotifWebBool(
        configService.getUserCreOverdueNotifWebBoolValue(configs) ?? false,
      );

      setValue(0);
    };
    loadConfigsCre();
  }, []);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function handleClickChangeActive(row: string): Promise<void> {
    switch (row) {
      case "CRE_DUE_TODAY_NOTIF_EMAIL_CLIENT_BOOL":
        await configService.updateAccountCreDueTodayNotifEmailClientBool(
          addMessages,
          setaLoading,
          navigate,
          !accountCreDueTodayNotifEmailClientBool,
        );
        setAccountCreDueTodayNotifEmailClientBool(!accountCreDueTodayNotifEmailClientBool);
        break;
      case "CRE_DUE_TODAY_NOTIF_WPP_CLIENT_BOOL":
        await configService.updateAccountCreDueTodayNotifWppClientBool(
          addMessages,
          setaLoading,
          navigate,
          !accountCreDueTodayNotifWppClientBool,
        );
        setAccountCreDueTodayNotifWppClientBool(!accountCreDueTodayNotifWppClientBool);
        break;
      case "CRE_DUE_TOMORROW_NOTIF_EMAIL_CLIENT_BOOL":
        await configService.updateAccountCreDueTomorrowNotifEmailClientBool(
          addMessages,
          setaLoading,
          navigate,
          !accountCreDueTomorrowNotifEmailClientBool,
        );
        setAccountCreDueTomorrowNotifEmailClientBool(!accountCreDueTomorrowNotifEmailClientBool);
        break;
      case "CRE_DUE_TOMORROW_NOTIF_WPP_CLIENT_BOOL":
        await configService.updateAccountCreDueTomorrowNotifWppClientBool(
          addMessages,
          setaLoading,
          navigate,
          !accountCreDueTomorrowNotifWppClientBool,
        );
        setAccountCreDueTomorrowNotifWppClientBool(!accountCreDueTomorrowNotifWppClientBool);
        break;
      case "CRE_OVERDUE_NOTIF_EMAIL_CLIENT_BOOL":
        await configService.updateAccountCreOverdueNotifEmailClientBool(
          addMessages,
          setaLoading,
          navigate,
          !accountCreOverdueNotifEmailClientBool,
        );
        setAccountCreOverdueNotifEmailClientBool(!accountCreOverdueNotifEmailClientBool);
        break;
      case "CRE_OVERDUE_NOTIF_WPP_CLIENT_BOOL":
        await configService.updateAccountCreOverdueNotifWppClientBool(
          addMessages,
          setaLoading,
          navigate,
          !accountCreOverdueNotifWppClientBool,
        );
        setAccountCreOverdueNotifWppClientBool(!accountCreOverdueNotifWppClientBool);
        break;
      case "WPP_DEFAULT_SEND_PERMITTED":
        await configService.updateAccountWppDefaultSendPermitted(
          addMessages,
          setaLoading,
          navigate,
          !accountWppDefaultSendPermitted,
        );
        setAccountWppDefaultSendPermitted(!accountWppDefaultSendPermitted);
        break;
      case "CRE_DUE_TODAY_NOTIF_EMAIL_BOOL":
        await configService.updateUserCreDueTodayNotifEmailBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTodayNotifEmailBool,
        );
        setUserCreDueTodayNotifEmailBool(!userCreDueTodayNotifEmailBool);
        break;
      case "CRE_DUE_TODAY_NOTIF_WPP_BOOL":
        await configService.updateUserCreDueTodayNotifWppBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTodayNotifWppBool,
        );
        setUserCreDueTodayNotifWppBool(!userCreDueTodayNotifWppBool);
        break;
      case "CRE_DUE_TODAY_NOTIF_PUSH_BOOL":
        await configService.updateUserCreDueTodayNotifPushBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTodayNotifPushBool,
        );
        setUserCreDueTodayNotifPushBool(!userCreDueTodayNotifPushBool);
        break;
      case "CRE_DUE_TODAY_NOTIF_WEB_BOOL":
        await configService.updateUserCreDueTodayNotifWebBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTodayNotifWebBool,
        );
        setUserCreDueTodayNotifWebBool(!userCreDueTodayNotifWebBool);
        break;
      case "CRE_DUE_TOMORROW_NOTIF_EMAIL_BOOL":
        await configService.updateUserCreDueTomorrowNotifEmailBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTomorrowNotifEmailBool,
        );
        setUserCreDueTomorrowNotifEmailBool(!userCreDueTomorrowNotifEmailBool);
        break;
      case "CRE_DUE_TOMORROW_NOTIF_WPP_BOOL":
        await configService.updateUserCreDueTomorrowNotifWppBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTomorrowNotifWppBool,
        );
        setUserCreDueTomorrowNotifWppBool(!userCreDueTomorrowNotifWppBool);
        break;
      case "CRE_DUE_TOMORROW_NOTIF_PUSH_BOOL":
        await configService.updateUserCreDueTomorrowNotifPushBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTomorrowNotifPushBool,
        );
        setUserCreDueTomorrowNotifPushBool(!userCreDueTomorrowNotifPushBool);
        break;
      case "CRE_DUE_TOMORROW_NOTIF_WEB_BOOL":
        await configService.updateUserCreDueTomorrowNotifWebBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreDueTomorrowNotifWebBool,
        );
        setUserCreDueTomorrowNotifWebBool(!userCreDueTomorrowNotifWebBool);
        break;
      case "CRE_OVERDUE_NOTIF_EMAIL_BOOL":
        await configService.updateUserCreOverdueNotifEmailBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreOverdueNotifEmailBool,
        );
        setUserCreOverdueNotifEmailBool(!userCreOverdueNotifEmailBool);
        break;
      case "CRE_OVERDUE_NOTIF_WPP_BOOL":
        await configService.updateUserCreOverdueNotifWppBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreOverdueNotifWppBool,
        );
        setUserCreOverdueNotifWppBool(!userCreOverdueNotifWppBool);
        break;
      case "CRE_OVERDUE_NOTIF_PUSH_BOOL":
        await configService.updateUserCreOverdueNotifPushBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreOverdueNotifPushBool,
        );
        setUserCreOverdueNotifPushBool(!userCreOverdueNotifPushBool);
        break;
      case "CRE_OVERDUE_NOTIF_WEB_BOOL":
        await configService.updateUserCreOverdueNotifWebBool(
          addMessages,
          setaLoading,
          navigate,
          !userCreOverdueNotifWebBool,
        );
        setUserCreOverdueNotifWebBool(!userCreOverdueNotifWebBool);
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack spacing={1}>
                <Titulo>Configurações Contas à Receber</Titulo>
              </Stack>
            </Stack>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleTabChange} aria-label="Configurações">
                <Tab label="Conta" {...a11yProps(0)} />
                <Tab label="Usuário" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Card sx={{ minWidth: 365, maxWidth: 745 }}>
                <CardContent sx={{ padding: "10px" }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Conta:</b> {configs.account[0]?.accountName}
                  </Typography>
                  {configs.account[0]?.accountId === 2 && (
                    <>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Mensagem padrão de WhatsApp para fechamento de contas à receber:</b>
                      </Typography>
                      <DebouncedTextArea
                        defaultValue={accountCreClosureWppDefaultMessage}
                        onDebounce={handleChangeAccountWppClosureDefaultMessage}
                        style={{ width: "96%", height: "250px" }}
                      />
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>
                          Mensagem padrão de WhatsApp para faturas de fechamento de contas à
                          receber:
                        </b>
                      </Typography>
                      <DebouncedTextArea
                        defaultValue={accountCreClosureWppBillMessage}
                        setValue={(e) => setAccountCreClosureWppBillMessage(e.target.value)}
                        onDebounce={handleChangeAccountCreClosureWppBillMessage}
                        style={{ width: "96%", height: "250px" }}
                      />
                    </>
                  )}
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Mensagem de notificação padrão de contas à receber em aberto:</b>
                  </Typography>
                  <DebouncedTextArea
                    defaultValue={accountCreBillNotDueDefaultMessage}
                    onDebounce={handleChangeAccountCreBillNotDueDefaultMessage}
                    style={{ width: "96%", height: "250px" }}
                  />
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>
                      Mensagem de notificação padrão de Notas Fiscais de contas à receber em aberto:
                    </b>
                  </Typography>
                  <DebouncedTextArea
                    defaultValue={accountCreBillNotDueInvoiceDefaultMessage}
                    setValue={(e) => setAccountCreBillNotDueInvoiceDefaultMessage(e.target.value)}
                    onDebounce={handleChangeAccountCreBillNotDueInvoiceDefaultMessage}
                    style={{ width: "96%", height: "250px" }}
                  />

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Mensagem de notificação padrão de contas à receber vencendo hoje:</b>
                  </Typography>
                  <DebouncedTextArea
                    defaultValue={accountCreBillDueTodayDefaultMessage}
                    setValue={(e) => setAccountCreBillDueTodayDefaultMessage(e.target.value)}
                    onDebounce={handleChangeAccountCreBillDueTodayDefaultMessage}
                    style={{ width: "96%", height: "250px" }}
                  />
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>
                      Mensagem de notificação padrão de Notas Fiscais de contas à receber vencendo
                      hoje:
                    </b>
                  </Typography>
                  <DebouncedTextArea
                    defaultValue={accountCreBillDueTodayInvoiceDefaultMessage}
                    setValue={(e) => setAccountCreBillDueTodayInvoiceDefaultMessage(e.target.value)}
                    onDebounce={handleChangeAccountCreBillDueTodayInvoiceDefaultMessage}
                    style={{ width: "96%", height: "250px" }}
                  />
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Mensagem de notificação padrão de contas à receber vencidas:</b>
                  </Typography>
                  <DebouncedTextArea
                    defaultValue={accountCreBillOverdueDefaultMessage}
                    setValue={(e) => setAccountCreBillOverdueDefaultMessage(e.target.value)}
                    onDebounce={handleChangeAccountCreBillOverdueDefaultMessage}
                    style={{ width: "96%", height: "250px" }}
                  />
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>
                      Mensagem de notificação padrão de Notas Fiscais de contas à receber vencidas:
                    </b>
                  </Typography>
                  <DebouncedTextArea
                    defaultValue={accountCreBillOverdueInvoiceDefaultMessage}
                    setValue={(e) => setAccountCreBillOverdueInvoiceDefaultMessage(e.target.value)}
                    onDebounce={handleChangeAccountCreBillOverdueInvoiceDefaultMessage}
                    style={{ width: "96%", height: "250px" }}
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <CustomTableContainer>
                        <CustomTable>
                          <TableHead>
                            <TableRow>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                }}
                              >
                                Notificações
                              </CustomTableCell>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                }}
                              >
                                Email
                              </CustomTableCell>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                }}
                              >
                                WhatsApp
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <CustomTableBody>
                            <CustomStripedTableRow
                              row={{ id: "CRE_DUE_TODAY_NOTIF_CLIENT_BOOL" }}
                              index={0}
                            >
                              <CustomTableCell>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  <b>
                                    Habilita notificações de contas à receber vencendo hoje para o
                                    cliente da fatura
                                  </b>
                                </Typography>
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TODAY_NOTIF_EMAIL_CLIENT_BOOL"
                                  isChecked={accountCreDueTodayNotifEmailClientBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TODAY_NOTIF_WPP_CLIENT_BOOL"
                                  isChecked={accountCreDueTodayNotifWppClientBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                            </CustomStripedTableRow>
                            <CustomStripedTableRow
                              row={{ id: "CRE_DUE_TOMORROW_NOTIF_CLIENT_BOOL" }}
                              index={1}
                            >
                              <CustomTableCell>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  <b>
                                    Habilita notificações de contas à receber vencendo amanhã para o
                                    cliente da fatura
                                  </b>
                                </Typography>
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TOMORROW_NOTIF_EMAIL_CLIENT_BOOL"
                                  isChecked={accountCreDueTomorrowNotifEmailClientBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TOMORROW_NOTIF_WPP_CLIENT_BOOL"
                                  isChecked={accountCreDueTomorrowNotifWppClientBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                            </CustomStripedTableRow>
                            <CustomStripedTableRow
                              row={{ id: "CRE_OVERDUE_NOTIF_CLIENT_BOOL" }}
                              index={2}
                            >
                              <CustomTableCell>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  <b>
                                    Habilita notificações de contas à receber vencidas para o
                                    cliente da fatura
                                  </b>
                                </Typography>
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_OVERDUE_NOTIF_EMAIL_CLIENT_BOOL"
                                  isChecked={accountCreOverdueNotifEmailClientBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_OVERDUE_NOTIF_WPP_CLIENT_BOOL"
                                  isChecked={accountCreOverdueNotifWppClientBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                            </CustomStripedTableRow>
                          </CustomTableBody>
                        </CustomTable>
                      </CustomTableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Grid container spacing={3}>
                <Grid item xs={12}></Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Card sx={{ minWidth: 660, maxWidth: 800 }}>
                <CardContent sx={{ alignContent: "initial" }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Usuário:</b> {configs.user[0]?.userName}
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <CustomTableContainer>
                        <CustomTable>
                          <TableHead>
                            <TableRow>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                }}
                              >
                                Notificações
                              </CustomTableCell>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                  width: "60px",
                                }}
                                align="center"
                              >
                                Email
                              </CustomTableCell>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                  width: "60px",
                                }}
                                align="center"
                              >
                                WhatsApp
                              </CustomTableCell>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                  width: "60px",
                                }}
                                align="center"
                              >
                                Push
                              </CustomTableCell>
                              <CustomTableCell
                                style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                  width: "60px",
                                }}
                                align="center"
                              >
                                Web
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <CustomTableBody>
                            <CustomStripedTableRow
                              row={{ id: "CRE_DUE_TODAY_NOTIF_EMAIL_BOOL" }}
                              index={0}
                            >
                              <CustomTableCell>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  <b>
                                    Habilita notificações de contas à receber vencendo hoje para o
                                    usuário do sistema
                                  </b>
                                </Typography>
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TODAY_NOTIF_EMAIL_BOOL"
                                  isChecked={userCreDueTodayNotifEmailBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TODAY_NOTIF_WPP_BOOL"
                                  isChecked={userCreDueTodayNotifWppBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TODAY_NOTIF_PUSH_BOOL"
                                  isChecked={userCreDueTodayNotifPushBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TODAY_NOTIF_WEB_BOOL"
                                  isChecked={userCreDueTodayNotifWebBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                            </CustomStripedTableRow>
                            <CustomStripedTableRow
                              row={{ id: "CRE_DUE_TOMORROW_NOTIF_BOOL" }}
                              index={1}
                            >
                              <CustomTableCell>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  <b>
                                    Habilita notificações de contas à receber vencendo amanhã para o
                                    usuário do sistema
                                  </b>
                                </Typography>
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TOMORROW_NOTIF_EMAIL_BOOL"
                                  isChecked={userCreDueTomorrowNotifEmailBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TOMORROW_NOTIF_WPP_BOOL"
                                  isChecked={userCreDueTomorrowNotifWppBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TOMORROW_NOTIF_PUSH_BOOL"
                                  isChecked={userCreDueTomorrowNotifPushBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_DUE_TOMORROW_NOTIF_WEB_BOOL"
                                  isChecked={userCreDueTomorrowNotifWebBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                            </CustomStripedTableRow>
                            <CustomStripedTableRow row={{ id: "CRE_OVERDUE_NOTIF_BOOL" }} index={2}>
                              <CustomTableCell>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  <b>
                                    Habilita notificações de contas à receber vencidas para o
                                    usuário do sistema
                                  </b>
                                </Typography>
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_OVERDUE_NOTIF_EMAIL_BOOL"
                                  isChecked={userCreOverdueNotifEmailBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_OVERDUE_NOTIF_WPP_BOOL"
                                  isChecked={userCreOverdueNotifWppBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_OVERDUE_NOTIF_PUSH_BOOL"
                                  isChecked={userCreOverdueNotifPushBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                <CheckedActionButton
                                  row="CRE_OVERDUE_NOTIF_WEB_BOOL"
                                  isChecked={userCreOverdueNotifWebBool}
                                  handleClickChangeChecked={handleClickChangeActive}
                                />
                              </CustomTableCell>
                            </CustomStripedTableRow>
                          </CustomTableBody>
                        </CustomTable>
                      </CustomTableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CustomTabPanel>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default ConfigCre;
