import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import RolesCheckboxGrid from "../../../../../components/elements/grids/RolesCheckboxGrid";
import Titulo from "../../../../../components/elements/Titulo";
import http from "../../../../services/http";
import { IMenu } from "../../../../types/Adm/Menu/IMenu";
import { IMenuNewData } from "../../../../types/Adm/Menu/IMenuNewData";
import { IRole } from "../../../../types/Adm/Role/IRole";
import { IResponse } from "../../../../types/Generic/IResponse";
import "./InclusaoUsuarios.css";
import { IUserNewData } from "../../../../types/Adm/User/IUserNewData";
import AccountsCheckboxGrid from "../../../../../components/elements/grids/AccountsCheckboxGrid";
import { IAccount } from "../../../../types/Adm/Account/IAccount";
import { IUser } from "../../../../types/Adm/User/IUser";
import { GlobalContext } from "../../../../context/GlobalContext";
import { sendPost } from "../../../../utils/httpUtils";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const WhatsAppMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function WhatsAppMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const InclusaoUsuarios = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [usuario, setUsuario] = useState<IUserNewData>({
    mainAccountId: 0,
    name: "",
    email: "",
    whatsapp: "",
    password: "",
    confirmPassword: "",
    active: true,
    bloqued: false,
    roles: [],
    accounts: [],
  } as IUserNewData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/admin/usuarios");
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    usuario.whatsapp = usuario.whatsapp.replace(/\D/g, "");
    if (usuario.whatsapp !== "") {
      if (usuario.whatsapp.length > 13 || usuario.whatsapp.length < 12) {
        addMessages(["Whatsapp inválido"]);
        return false;
      }
    }
    if (usuario.password !== usuario.confirmPassword) {
      addMessages(["Senhas não conferem"]);
      return false;
    }
    if (usuario.password.length < 6) {
      addMessages(["Senha deve ter no mínimo 6 caracteres"]);
      return false;
    }

    return true;
  }
  async function handleClickSalvar() {
    if (!validateForm()) {
      return;
    }
    let url = `v1/admin/users/new`;
    const dados = usuario;
    try {
      const response = await sendPost<IUser>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/admin/usuarios");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;

    let valor = type === "checkbox" ? target.checked : target.value;

    setUsuario((prevUsuario) => ({ ...prevUsuario, [name]: valor }));
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>, papel: IRole) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setUsuario((prevUsuario) => ({ ...prevUsuario, roles: [...prevUsuario.roles, papel] }));
    } else {
      setUsuario((prevUsuario) => ({
        ...prevUsuario,
        roles: prevUsuario.roles.filter((role) => role.id !== papel.id),
      }));
    }
  };

  const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>, conta: IAccount) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setUsuario((prevUsuario) => ({ ...prevUsuario, accounts: [...prevUsuario.accounts, conta] }));
    } else {
      setUsuario((prevUsuario) => ({
        ...prevUsuario,
        accounts: prevUsuario.accounts.filter((account) => account.id !== conta.id),
      }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setUsuario({
      ...usuario,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Usuário</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  value={usuario.name}
                  name="name"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={usuario.email}
                  name="email"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="WhastApp"
                  value={usuario.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: WhatsAppMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Senha"
                  value={usuario.password}
                  name="password"
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirmar Senha"
                  value={usuario.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usuario.active || false}
                      onChange={handleChange}
                      name="active"
                      value={usuario.active}
                    />
                  }
                  label="Ativo"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usuario.bloqued || false}
                      onChange={handleChange}
                      name="bloqued"
                      value={usuario.bloqued}
                    />
                  }
                  label="Bloqueado"
                />
              </Grid>
              <RolesCheckboxGrid
                papeisSelecionados={usuario.roles}
                handleChange={handleRoleChange}
              />
              <AccountsCheckboxGrid
                contasSelecionadas={usuario.accounts}
                handleChange={handleAccountChange}
              />
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-label">Conta Principal</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={usuario.mainAccountId}
                  label="Conta Principal"
                  name="mainAccountId"
                  onChange={handleSelectChange}
                >
                  {usuario.accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default InclusaoUsuarios;
